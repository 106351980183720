<template>
  <b-modal
    id="product-add-modal"
    centered
    :title="isEdit ? $t('add_product') : $t('add_product')"
    :ok-title="isEdit ? $t('update') : $t('append')"
    :cancel-title="this.$t('cancel')"
    @ok="onSubmit"
    @show="onShow"
  >
    <!-- {{category_id}}
    <br> -->
    <!-- <pre>
      {{editData}}
    </pre> -->
 
    <b-row>
      <b-col cols="6">
        <b-form-group :label="this.$t('naming') + ':'">
          <b-form-input
            v-model="modalData.name"
            type="text"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="this.$t('Information') + ':'">
          <b-form-input
            v-model="modalData.information"
            type="text"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="this.$t('priority') + ':'">
          <b-form-input
            v-model="modalData.priority"
            type="number"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="this.$t('Block') + ':'">
          <b-form-input
            v-model="modalData.block"
            type="number"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="this.$t('Piece_block') + ':'">
          <b-form-input
            v-model="modalData.piece_block"
            type="number"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group :label="this.$t('Kg') + ':'">
          <b-form-input
            v-model="modalData.kg"
            type="number"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group>
          <label>{{ $t("category") }}</label>
          <v-select
            v-model="modalData.category_id"
            :options="categoryOptions"
            label="name"
            :placeholder="$t('category')"
            @input="onSelectCategory"
          />
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group>
          <label>{{ $t("department") }}</label>
          <v-select
            v-model="modalData.department_id"
            label="name"
            :placeholder="$t('department')"
            :options="GET_DEPARTMENTS.data && GET_DEPARTMENTS.data.departments"
            @input="onSelectDepartment"
          />
          <!-- @input="onSelectDepartment" -->
        </b-form-group>
      </b-col>

      <b-col cols="6">
        <b-form-group>
          <label>{{ $t("brand") }}</label>
          <v-select
            v-model="modalData.brand_id"
            label="name"
            :placeholder="$t('brand')"
            :options="GET_BRANDS.data && GET_BRANDS.data.brands"
          />
          <!-- @input="onSelectDepartment" -->
        </b-form-group>
      </b-col>

<!-- First Image -->
      <b-col cols="12">
        <b-form-group class="text-cetner">
          <div
            class="drag_place my-2"
            v-if="!modalData.showImage || modalData.showImage === 'http://185.17.66.176:60402/img/Test image/'"
          >
            {{ this.$t("main_image") }}
          </div>

          <b-img
            v-else
            v-model="modalData.image"
            class="d-block mx-auto w-100 px-4 py-2"
            :src="modalData.showImage"
          />

        </b-form-group>
        <b-form-group>
          <b-form-file
            id="wildcard"
            accept="image/*"
            @input="loadImg"
            v-model="modalData.main_image"
          />
        </b-form-group>
      </b-col>

      <!-- Second Image -->
     
      <b-col cols="12">
        <b-form-group class="text-cetner">
          <div
            class="drag_place my-2"
            v-if="!modalData.showImage_2 || modalData.showImage_2 === 'http://185.17.66.176:60402/img/Data2022/'"
          >
            {{ this.$t("category_image") }}
          </div>

          <b-img
            v-else
            v-model="modalData.image_2"
            class="d-block mx-auto w-100 px-4 py-2"
            :src="modalData.showImage_2"
          />

        </b-form-group>
        <b-form-group>
          <b-form-file

            accept="image/*"
            @input="loadImg"
            v-model="modalData.category_image" 
          />
        </b-form-group>
      </b-col>

      <!-- Third ImAGE -->
      
      <b-col cols="12">
        <b-form-group class="text-cetner">
          <div
            class="drag_place my-2"
            v-if="!modalData.showImage_3 || modalData.showImage_3 === 'http://185.17.66.176:60402/img/Data2022/'"
          >
            {{ this.$t("product_page") }}
          </div>

          <b-img
            v-else
            v-model="modalData.image_3"
            class="d-block mx-auto w-100 px-4 py-2"
            :src="modalData.showImage_3"
          />

        </b-form-group>
        <b-form-group>
          <div class="d-flex">
            <button @click="deleteImages" class="imageDeleteBtn btn-danger" style="width: 240px; font-size: 12px">Удалить изображения</button>
            <b-form-file
            
              accept="image/*"
              @input="loadImg"
              v-model="modalData.product_page_image"
            />
          </div>
        </b-form-group>
      </b-col>
      <div class="d-flex justify-content-start mt-1"></div>
    </b-row>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import vSelect from "vue-select";
import {
  BModal,
  BFormInput,
  BFormFile,
  BFormGroup,
  BRow,
  BCol,
  BImg,
} from "bootstrap-vue";
import brand from '@/store/brand';

export default {
  name: "BrandAddEditModal",
  components: {
    BModal,
    BFormInput,
    BFormFile,
    BFormGroup,
    BRow,
    BCol,
    BImg,
    vSelect,
  },
  data() {
    return {
      modalData: {
        id: "",
        category_id: "",
        department_id: "",
        brand_id: "",
        name: "", 
        information: "",
        piece_block: "",
        priority: "",
        block: "",
        kg: "",
        image: "",
        image_2: "",
        image_3: "",
        showImage: "",
        showImage_2: "",
        showImage_3: "",
        main_image: "",
        category_image: "",
        product_page_image: ""
      },
    };
  },

  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    editData: {
      type: Object,
      default: () => {},
    },
    categoryOptions: {
      type: Array,
      default: () => [],
    },
    category_id: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapGetters("department", ["GET_DEPARTMENTS"]),
    ...mapGetters("brand", ["GET_BRANDS"]),
    ...mapGetters("category", ["GET_PRODUCTS"]),

  },

  methods: {
    // ...mapActions("department", ["FETCH_DEPARTMENTS"]),
    ...mapActions("category", ["FETCH_CATEGORIES"]),
    ...mapActions("brand", ["FETCH_BRANDS", "DELETE_BRAND"]),
    ...mapActions("department", ["FETCH_DEPARTMENTS", "ADD_DEPARTMENT"]),
    ...mapActions("brand", ["ADD_BRAND", "UPDATE_BRAND"]),
    ...mapActions("product", ["ADD_PRODUCTS", "UPDATE_PRODUCT"]),

    onSelectCategory() {
      this.FETCH_DEPARTMENTS({
        category_id: this.modalData.category_id.id,
      });
    },
    onSelectDepartment() {
      this.FETCH_BRANDS({
        department_id: this.modalData.department_id.id,
      });
    },

    onSubmit() {
      const formData = new FormData();
      formData.append("category_id", this.modalData.category_id.id);
      formData.append("department_id", this.modalData.department_id.id);
      formData.append("brand_id", this.modalData.brand_id.id);
      formData.append("name", this.modalData.name);
      formData.append("information", this.modalData.information);
      formData.append("piece_block", this.modalData.piece_block);
      formData.append("priority", this.modalData.priority);
      formData.append("block", this.modalData.block);
      formData.append("kg", this.modalData.kg);
      // formData.append("department_id", this.modalData.department_id.id);
      formData.append("main_image", this.modalData.main_image);
      formData.append("category_image",  this.modalData.category_image);
      formData.append("product_page_image",  this.modalData.product_page_image);

      if (this.editData?.id) {
        formData.append("id", this.editData.id);
      }
      if (this.isEdit) {  
        this.UPDATE_PRODUCT({
          data: formData,
        }).then(() => {
            this.$bvToast.toast(this.$t("toast.success"), {
              title: this.$t("success").toUpperCase(),
              variant: "success",
              solid: true,
            })
            this.$emit("restart");
          }).catch(() => {
            this.$bvToast.toast(this.$t("toast.error"), {
              title: this.$t("error").toUpperCase(),
              variant: "danger",
              solid: true,
            });
          });
    } else {
        this.ADD_PRODUCTS(formData).then(() => {
            this.$bvToast.toast(this.$t("toast.success"), {
              title: this.$t("success").toUpperCase(),
              variant: "success",
              solid: true,
            })
            this.$emit("restart");
          }).catch(() => {
            this.$bvToast.toast(this.$t("toast.error"), {
              title: this.$t("error").toUpperCase(),
              variant: "danger",
              solid: true,
            });
          });
 
    }
    this.handleInitialize()
  },
    onShow() {
      if (this.isEdit) {
        this.modalData.category_id = this.editData.category
        this.modalData.department_id = this.editData.bolim
        this.modalData.brand_id = this.editData.brend
        this.modalData.name = this.editData.name;
        this.modalData.priority = this.editData.priority;
        this.modalData.information = this.editData.information;
        this.modalData.block = this.editData.blok;
        this.modalData.piece_block = this.editData.idDonaBlok;
        this.modalData.kg = this.editData.kg;
        this.modalData.image = this.editData.image;
        this.modalData.image_2 = this.editData.image;
        this.modalData.image_3 = this.editData.image;
        this.modalData.showImage = this.editData.image_adress;
         this.modalData.showImage_2 = this.editData.productImages[1].path;
        this.modalData.showImage_3 = this.editData.productImages[2].path;
        this.modalData.category_image = this.editData.productImages[1].path;
        this.modalData.product_page_image = this.editData.productImages[2].path;
 
      } else {
        this.handleInitialize()
      }
    },

    loadImg() {
      this.modalData.image = this.modalData.main_image;
      this.modalData.image_2 = this.modalData.category_image;
      this.modalData.image_3 = this.modalData.product_page_image;

      this.modalData.showImage = URL.createObjectURL(this.modalData.main_image);
      this.modalData.showImage_2 = URL.createObjectURL(this.modalData.category_image);
      this.modalData.showImage_3 = URL.createObjectURL(this.modalData.product_page_image);

    },
      handleInitialize () {
      this.modalData.category_id = null
      this.modalData.department_id = null
      this.modalData.brand_id = null
      this.modalData.name = null
      this.modalData.priority = null
      this.modalData.information = null
      this.modalData.block = null
      this.modalData.piece_block = null
      this.modalData.kg = null
      this.modalData.image = null
      this.modalData.image_2 = null
      this.modalData.image_3 = null
      this.modalData.showImage = null
      this.modalData.showImage_2 = null
      this.modalData.showImage_3 = null
    },
    deleteImages () {
      this.modalData.image = ""
      this.modalData.category_image = ""
      this.modalData.product_page_image = ""
      this.modalData.image_2 = ""
      this.modalData.image_3 = ""
      this.modalData.showImage = ""
      this.modalData.showImage_2 = ""
      this.modalData.showImage_3 = ""
      
    }
  },
  async mounted() {
    this.handleInitialize()
    await this.FETCH_CATEGORIES({ limit: 100 }).then(() => {
      this.category_id = this.GET_PRODUCTS.data.categories[0].id;
    });

    await this.FETCH_DEPARTMENTS({
      limit: 5,
      category_id: this.GET_PRODUCTS.data.categories[0].id,
    }).then(() => {
      this.department_id = this.GET_DEPARTMENTS.data.departments[0].id;
    });

    // await this.FETCH_BRANDS({
    //   limit: 10,
    //   category_id: this.GET_PRODUCTS.data.categories[0].id,
    //   department_id: this.GET_DEPARTMENTS.data.departments[0].id,
    // });

    this.$nextTick(() => {
      this.isBusy = false;
    });
  },
};
</script>

<style>
.drag_place {
  width: 100%;
  height: 140px;
  border: 2px dashed #ccc;
  border-radius: 5px;
  text-align: center;
  line-height: 140px;
  font-size: 20px;
  color: #ccc;
  cursor: pointer;
}

.imageDeleteBtn {
  display: flex;
  align-items: center;
  border: none;
  border-radius: 10px;
  margin-right: 10px;
}
</style>
