<template>
  <div class="pb-1">
    <h3>
      {{ $t("product") }}
    </h3>
    <!-- {{GET_PRODUCTS.data.categories}} -->

    <Filters
      :search="true"
      :category="true"
      :department="true"
      :brand="true"
      :categoryData="categoryData"
      :departmentData="departmentData"
      :brandData="brandData"
      @search="onSearch"
      @selectCategory="onSelectCategory"
      @selectDepartment="onSelectDepartment"
      @selectBrand="onSelectBrand"
      @addModal="showModal"
    />

    <b-card>
      <!-- <pre>
        {{ GET_PRODUCTS_LIST.data.products }}
      </pre> -->
      <b-table
        :items="GET_PRODUCTS_LIST.data && GET_PRODUCTS_LIST.data.products"
        :fields="fields"
        :busy="isBusy"
        responsive="sm"
        hover
        striped
        show-empty
      >
        <template #empty>
          <h5 class="text-center py-2">{{ $t("no_data") }}</h5>
        </template>

        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong class="mx-2">
              <span v-if="$i18n.locale == 'uz'"> Yuklanmoqda... </span>
              <span v-else-if="$i18n.locale == 'ru'"> Загрузка... </span>
            </strong>
          </div>
        </template>

        <template #cell(image)="row">
          <div class="text-center">
            <img
              :src="row.item.image_adress"
              v-if="row.item.image"
              class="img-thumbnail"
              style="height: 100px"
            />

            <!-- placeholder image -->
            <div v-else class="no_image">
              {{ $t("no_image") }}
            </div>
          </div>
        </template>

        <template #cell(actions)="row">
          <div class="text-center d-flex justify-content-center">
            <b-button
              variant="primary"
              size="sm"
              class="mr-1"
              @click="showModal(row.item)"
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-button
              variant="danger"
              size="sm"
              @click="showDeleteModal(row.item)"
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
          </div>
        </template>
      </b-table>
    </b-card>

    <Pagination
      :users_count="
        GET_PRODUCTS_LIST.data &&
        GET_PRODUCTS_LIST.data.pagination[0].totalItems
      "
      @changePaginationValue="changePaginationValue"
    />

    <ProductAddModal
      :isEdit="isEdit"
      :editData="editData"
      :category_id="category_id"
      :department_id="department_id"
      :categoryOptions="GET_PRODUCTS.data && GET_PRODUCTS.data.categories"
      :departmentOptions="
        GET_DEPARTMENTS.data ? GET_DEPARTMENTS.data.departments : []
      "
      @restart="restart"
    />

    <AreYouSureModal @ok="deleteProduct" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import Filters from "@/components/Filters.vue";
import Pagination from "@/components/Pagination.vue";
import ProductAddModal from "./components/ProductAddModal.vue";
import AreYouSureModal from "@/components/AreYouSureModal.vue";
import {
  BTable,
  BCard,
  BButton,
  BSpinner,
  BModal,
  BFormInput,
  BFormSelect,
  BFormGroup,
} from "bootstrap-vue";
import department from "@/store/department";
export default {
  components: {
    BTable,
    BCard,
    BButton,
    BSpinner,
    BModal,
    BFormInput,
    BFormSelect,
    BFormGroup,
    Filters,
    Pagination,
    AreYouSureModal,
    ProductAddModal,
  },
  data() {
    return {
      isBusy: true,
      editData: {},
      deleteId: null,
      isEdit: false,
      category_id: null,
      department_id: null,
      brand_id: null,
      params: {
        search: "",
        limit: 10,
      },
      pagination: {
        total: 4,
        current: 1,
        from: 1,
        next: 2,
        perpage: 10,
        previoust: 0,
        to: 10,
        totalItems: 38,
      },
      fields: [
        {
          key: "image",
          label: this.$t("image"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "name",
          label: this.$t("name"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "priority",
          label: this.$t("priority"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "donaBlok",
          label: this.$t("donaBlok"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "blok",
          label: this.$t("blok"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "kg",
          label: this.$t("weight(kg)"),
          tdClass: "text-center",
          thClass: "text-center",
        },
        {
          key: "actions",
          label: this.$t("actions"),
          tdClass: "text-center",
          thClass: "text-center",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("department", ["GET_DEPARTMENTS"]),
    ...mapGetters("category", ["GET_PRODUCTS"]),
    ...mapGetters("brand", ["GET_BRANDS"]),
    ...mapGetters("product", ["GET_PRODUCTS_LIST"]),
    categoryData() {
      return this.GET_PRODUCTS.data?.categories;
    },
    departmentData() {
      return this.GET_DEPARTMENTS.data?.departments;
    },
    brandData() {
      return this.GET_BRANDS.data?.brands;
    },
  },
  // async mounted() {
  //   await this.FETCH_PRODUCTS({ limit: 1000 });
  //   this.$nextTick(() => {
  //     this.isBusy = false;
  //   });

  //   await this.FETCH_CATEGORIES({ limit: 100 }).then(() => {
  //     this.category_id = this.GET_PRODUCTS.data.categories[0].id;
  //   });
  //   // await this.FETCH_DEPARTMENTS({
  //   //   limit: 5,
  //   //   category_id: this.GET_PRODUCTS.data.categories[0].id,
  //   // }).then(() => {
  //   //   this.department_id = this.GET_DEPARTMENTS.data.departments[0].id;
  //   // });
  //   await this.FETCH_BRANDS({
  //     limit: 10,
  //     category_id: this.GET_PRODUCTS.data.categories[0].id,
  //     department_id: this.GET_DEPARTMENTS.data.departments[0].id,
  //   });
  //   this.$nextTick(() => {
  //     this.isBusy = false;
  //   });
  // },
  methods: {
    ...mapActions("category", ["FETCH_CATEGORIES"]),
    ...mapActions("product", ["FETCH_PRODUCTS", "DELETE_PRODUCT"]),
    ...mapActions("brand", ["FETCH_BRANDS"]),
    ...mapActions("department", ["FETCH_DEPARTMENTS"]),

    async restart() {
      this.isBusy = true;
      await this.FETCH_PRODUCTS();
      this.$nextTick(() => {
        this.isBusy = false;
      });
    },
    // search filter
    onSearch(search) {
      console.log("search", search);
      this.params.search = search;
      this.FETCH_PRODUCTS(this.params);
      // this.FETCH_CATEGORIES({ search });
      // this.FETCH_DEPARTMENTS({ search });
    },

    // Select Category
    async onSelectCategory(id) {
      this.category_id = id;
      this.isBusy = true;
      await this.FETCH_DEPARTMENTS({
        category_id: id,
      })
        .then(() => {
          this.$nextTick(() => {
            this.isBusy = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
      await this.FETCH_PRODUCTS({
        ...this.params,
        category_id: this.category_id,
      })
        .then(() => {
          this.$nextTick(() => {
            this.isBusy = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Select department
    async onSelectDepartment(id) {
      this.department_id = id;
      this.isBusy = true;
      await this.FETCH_BRANDS({
        department_id: id.id,
      });
      await this.FETCH_PRODUCTS({ ...this.params, department_id: id.id })
        .then(() => {
          this.$nextTick(() => {
            this.isBusy = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // Select Brand
    async onSelectBrand(id) {
      this.isBusy = true;
      await this.FETCH_PRODUCTS({
        ...this.params,
        category_id: this.category_id,
        brand_id: id.id,
      })
        .then(() => {
          this.$nextTick(() => {
            this.isBusy = false;
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // open modal
    showModal(item) {
      if (item) {
        this.editData = item;
        this.isEdit = true;
      } else {
        this.isEdit = false;
        this.editData = {};
      }
      this.$nextTick(() => {
        this.$bvModal.show("product-add-modal");
      });
    },
    // open delete modal
    showDeleteModal(item) {
      this.deleteId = item.id;
      this.$nextTick(() => {
        this.$bvModal.show("are-you-sure-modal");
      });
    },
    deleteProduct() {
      this.DELETE_PRODUCT(this.deleteId)
        .then(() => {
          this.$bvToast.toast(this.$t("toast.success"), {
            title: this.$t("success").toUpperCase(),
            variant: "success",
            solid: true,
          });
          this.$emit("restart");
        })
        .catch(() => {
          this.$bvToast.toast(this.$t("toast.error"), {
            title: this.$t("error").toUpperCase(),
            variant: "danger",
            solid: true,
          });
          this.$emit("restart");
        });
    },
    // pagination
    changePaginationValue(value) {
      this.isBusy = true;
      this.FETCH_PRODUCTS({
        ...this.params,
        page: value.page,
        limit: value.page_size,
      }).then(() => {
        this.$nextTick(() => {
          this.isBusy = false;
        });
      });
    },
  },
};
</script>
<style scoped>
.no_image {
  margin: 0 auto;
  width: 150px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  border: 1px dashed #ddd !important;
}
</style>
